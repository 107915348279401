import http from '@/http';

/**
 * Proxy call to /api/v3/orchestrationplan/enrich_plan
 * @param planId
 */
export function enrichPlan(plan) {
    return http({
        method: 'POST',
        url: '/v3orchestration/enrichplan',
        data: { plan },
    });
}

/**
 * Proxy call to /api/v3/orchestrationplan/get_plans
 */
export function getPlans() {
    return http({
        method: 'POST',
        url: '/v3orchestration/getplans',
        data: {},
    });
}

/**
 * Proxy call to /api/v3/orchestrationplan/get_plan
 * @param planId
 */
export function getPlanDetails(data) {
    return http({
        method: 'POST',
        url: '/v3orchestration/getplan',
        data: {
            ids: [data.id],
            dont_enrich_with_templates: data?.dontEnrichWithTemplates || false,
            ...(data?.timestamp && { snapshot_timestamp: data.timestamp }),
        },
    });
}

/**
 * Proxy call to /api/v3/orchestration/get_execution
 * @param {string} execId
 * @returns AxiosPromise<DnoResponseBase & { execution: Record<string, any> }>
 */
export function getExecution(execId) {
    return http({
        method: 'POST',
        url: '/v3orchestration/getexecution',
        data: {
            execution_id: execId,
        },
    });
}

/**
 * Proxy call to /api/v3/orchestration/get_executions_es
 */
export function getExecutionsEs({ pageSize, filters, fromPage, sortBy }) {
    return http({
        method: 'POST',
        url: '/v3orchestration/getexecutionses',
        data: {
            page_size: pageSize,
            from_page: fromPage,
            sort_by: sortBy,
            filters,
        },
    });
}

/**
 * Proxy call to /api/v3/orchestration/get_execution_history
 * @param execId
 */
export function getExecutionHistory(execId) {
    return http({
        method: 'POST',
        url: '/v3orchestration/getexecutionhistory',
        data: {
            execution_id: execId,
        },
    });
}

/**
 * Proxy call to /api/v3/orchestration/get_state_history
 * @param execId
 */
export function getStateHistory(execId) {
    return http({
        method: 'POST',
        url: '/v3orchestration/getstatehistory',
        data: {
            execution_id: execId,
        },
    });
}

/**
 * Proxy call to /api/v3/orchestration/get_mapped_executions
 * @param data
 * @returns AxiosPromise<DnoResponseBase & { mappings: any[][]}>
 */
export function getMappedExecutions(data) {
    return http({
        method: 'POST',
        url: '/v3orchestration/getmappedexecutions',
        data,
    });
}

/**
 * Proxy call to /api/v3/orchestration/schedule_execution
 * @param planId
 * @param startAt
 * @param input
 */
export function scheduleExecution(planId, input, { startAt }) {
    return http({
        method: 'POST',
        url: '/v3orchestrationwrite/scheduleexecution',
        data: {
            plan_id: planId,
            start_at: startAt,
            input,
        },
    });
}

/**
 * Proxy call to /api/v3/orchestration/run_plan
 * @param planId
 * @param input
 */
export function runPlan(planId, input) {
    return http({
        method: 'POST',
        url: '/v3orchestrationwrite/runplan',
        data: {
            plan_id: planId,
            input,
        },
    });
}

/**
 * Proxy call to /api/v3/orchestration/halt_execution
 * @param planExecutionId
 */
export function haltExecution(planExecutionId) {
    return http({
        method: 'POST',
        url: '/v3orchestrationwrite/haltexecution',
        data: {
            plan_execution_id: planExecutionId,
        },
    });
}

/**
 * Proxy call to /api/v3/orchestration/get_execution_hierarchy
 * @param executionId
 */
export function getExecutionHierarchy(executionId) {
    return http({
        method: 'POST',
        url: '/v3orchestrationwrite/getexecutionhierarchy',
        data: {
            execution_id: executionId,
        },
    });
}

/**
 * Proxy call to /api/v3/orchestrationplan/add_plan
 */
export function addPlan(plan) {
    return http({
        method: 'POST',
        url: '/v3orchestrationwrite/addplan',
        data: {
            plan,
        },
    });
}

/**
 * Proxy call to /api/v3/orchestrationplan/update_plan
 */
export function updatePlan(version, forceUpdate, plan) {
    return http({
        method: 'POST',
        url: '/v3orchestrationwrite/updateplan',
        data: {
            version,
            force_update: forceUpdate,
            plan,
        },
    });
}

/**
 * Proxy call to /api/v3/orchestrationplan/update_plan_state
 */
export function updatePlanState(version, planId, state) {
    return http({
        method: 'POST',
        url: '/v3orchestrationwrite/updateplanstate',
        data: {
            version,
            plan_id: planId,
            state,
        },
    });
}

/**
 * Proxy call to /api/v3/orchestrationplan/get_templates
 */
export function getOETemplates(templateId) {
    let data = {};
    if (templateId) {
        data = {
            ids: [templateId],
        };
    }
    return http({
        method: 'POST',
        url: '/v3orchestration/gettemplates',
        data,
    });
}

/**
 * Proxy call to /api/v3/orchestrationplan/add_template
 */
export function addOETemplate(data) {
    return http({
        method: 'POST',
        url: '/v3orchestrationwrite/addtemplate',
        data,
    });
}

/**
 * Proxy call to /api/v3/orchestrationplan/update_template
 */
export function updateOETemplate(data) {
    return http({
        method: 'POST',
        url: '/v3orchestrationwrite/updatetemplate',
        data,
    });
}

/**
 * Proxy call to /api/v3/orchestrationplan/delete_template
 */
export function deleteOETemplate(templateName) {
    const data = {
        template: {
            name: templateName,
        },
    };

    return http({
        method: 'POST',
        url: '/v3orchestrationwrite/deletetemplate',
        data,
    });
}

/**
 * Proxy call to /api/v3/orchestration/restart_state
 */
export function restartState(data) {
    return http({
        method: 'POST',
        url: '/v3orchestrationwrite/restartstate',
        data,
    });
}

/**
 * Proxy call to /api/v3/orchestration/multi_restart_state
 */
export function multiRestartState(data) {
    return http({
        method: 'POST',
        url: '/v3orchestrationwrite/multirestartstate',
        data: { restart_data: data },
    });
}

/**
 * Proxy call to /api/v3/orchestration/callback
 */
export function callbackExecution(data) {
    return http({
        method: 'POST',
        url: '/v3orchestrationwrite/callback',
        data,
    });
}

/**
 * Proxy call to /api/v3/orchestration/execute_plan_immediately
 */
export function executePlanImmediately(data) {
    return http({
        method: 'POST',
        url: '/v3orchestrationwrite/executeplanimmediately',
        data,
    });
}

/**
 * Proxy call to /api/v3/orchestration/get_paused_executions
 * @param page_size
 * @param paging_state
 */
export function getPausedExecutions({ page_size, paging_state = null }) {
    const data = { page_size };

    if (paging_state) {
        data.paging_state = paging_state;
    }

    return http({
        method: 'POST',
        url: '/v3orchestration/getpausedexecutions',
        data,
    });
}

/**
 * Proxy call to /api/v3/orchestration/update_pause_duration
 * @param executionId
 * @param duration
 */
export function updatePauseDuration(executionId, duration) {
    return http({
        method: 'POST',
        url: '/v3orchestrationwrite/updatepauseduration',
        data: {
            execution_id: executionId,
            duration,
        },
    });
}

/**
 * Proxy call to /api/v3/orchestration/update_scheduled_execution_time
 */
export function updateScheduledExecutionTime(data) {
    return http({
        method: 'POST',
        url: '/v3orchestrationwrite/updatescheduledexecutiontime',
        data: {
            execution_id: data.executionId,
            ...(data?.duration && { duration: data.duration }),
            ...(data?.scheduleToTimestamp && { schedule_to_timestamp: data.scheduleToTimestamp }),
        },
    });
}

/**
 * Proxy call to /api/v3/orchestration/schedule_multi_execution
 */
export function scheduleMultiExecution(data) {
    return http({
        method: 'POST',
        url: '/v3orchestration/schedulemultiexecution',
        data,
    });
}

export default {
    getPlans,
};
