
















































































































// Components
import AppSpinner from '@/components/partials/AppSpinner.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import SubSidebarMixin from '@/components/partials/SubSidebarMixin.vue';
import SettingsSubSidebarMixin from '@/__new__/features/settings/SettingsSubSidebarMixin.vue';
import AbstractSubSidebarPageWrapper from '@/components/layout/AbstractSubSidebarPageWrapper.vue';
import SubSidebar from '@/components/layout/SubSidebar.vue';

// Helpers
import { isEmpty } from 'lodash';
import RouteNames from '@/router/routeNames';
import * as Sentry from '@sentry/vue';
import AppTable from '@/components/partials/AppTable.vue';
import { ICON_TYPES } from '@/common/iconHelper';
import { mapActions, mapGetters } from 'vuex';
import { Modules } from '@/store/store';
import Actions, { Getters } from '@/store/mutation-types';
import tableColumnType from '@/common/filterTable';
import AbstractListPageWrapper from '@/components/layout/AbstractListPageWrapper.vue';
import EntityOverview from '@/components/partials/entityOverview/EntityOverview.vue';
import ENTITY_TYPES from '@/common/entities/entityTypes';
import OverviewHeaderV2 from '@/components/partials/entityOverview/OverviewHeaderV2.vue';
import AppOverviewBlock from '@/components/partials/AppOverviewBlock.vue';
import { CollapsibleListItem } from '@/common/AppCollapsibleListHelper';
import QuietHoursDisplayRules from '@/__new__/features/settings/quietHours/QuietHoursDisplayRules.vue';
import { getQuietHours } from '@/__new__/services/dno/quietHours/http/quietHours';
import { getBeautifulBoolean } from '@/common/formatting';
import { isUserAllowed } from '@/services/permissions/permissions.service';

export default {
    name: 'CampaignCategories',
    components: {
        QuietHoursDisplayRules,
        AppOverviewBlock,
        EntityOverview,
        OverviewHeaderV2,
        AbstractListPageWrapper,
        AppTable,
        AppButton,
        AppSpinner,
        SubSidebar,
        AbstractSubSidebarPageWrapper,
    },
    mixins: [SubSidebarMixin, SettingsSubSidebarMixin],
    data() {
        return {
            ENTITY_TYPES,
            BUTTON_TYPES,
            ICON_TYPES,
            hasRules: null,
            isDataLoading: false,
            quietHoursData: null,
            selectedCategory: null,
            selectedCategoryId: null,
            isOverviewEnabled: false,
            qhResponse: null,
        };
    },
    computed: {
        ...mapGetters(Modules.campaigns, {
            allCategories: [Getters.GET_CAMPAIGN_CATEGORIES],
        }),
        qhRulesOfSelectedCategory(): any {
            if (this.selectedCategory?.qhRules) {
                return this.qhResponse.filter((qhr: any) => this.selectedCategory.qhRules.includes(qhr.id));
            }
            return [];
        },
        tableColumnsData(): any {
            return [
                {
                    name: this.$t('generic.name'),
                    key: 'name',
                    field: 'name',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$t('settings.campaignCategories.campaignType'),
                    key: 'campaignType',
                    field: 'campaignType',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$t('campaigns.priority.campaignPriority'),
                    key: 'campaignPriority',
                    field: 'campaignPriority',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
            ];
        },
        generalOverviewItems(): CollapsibleListItem[] {
            const overviewItems = [
                {
                    name: this.$t('generic.general'),
                    isCollapsed: false,
                    rows: [
                        {
                            name: this.$t('settings.campaignCategories.inboxCategoryName'),
                            value: this.selectedCategory.inboxName,
                        },

                        {
                            name: this.$t('campaigns.priority.campaignPriority'),
                            value: this.selectedCategory.campaignPriority,
                        },
                        {
                            name: this.$t('settings.campaignCategories.campaignType'),
                            value: this.selectedCategory.campaignType,
                        },
                        {
                            name: this.$t('settings.campaignCategories.ignoreGlobalDailyLimits'),
                            value: getBeautifulBoolean(this.selectedCategory.ignoreDailyLimits),
                        },
                        {
                            name: this.$t('settings.campaignCategories.applyLimits'),
                            value: getBeautifulBoolean(this.selectedCategory.applyLimits),
                        },
                    ],
                },
            ];
            if (this.selectedCategory.applyLimits) {
                overviewItems.push({
                    isCollapsed: false,
                    name: 'Limits',
                    rows: [
                        {
                            name: this.$t('settings.campaignCategories.limitOfMessages'),
                            value: this.selectedCategory.limitNumber,
                        },
                        {
                            name: this.$t('settings.campaignCategories.daysAppliedForLimit'),
                            value: this.selectedCategory.limitPerDay,
                        },
                    ],
                });
            }
            return overviewItems;
        },
    },
    created() {
        this.$Progress.start();
        this.isDataLoading = true;
        const promises = [this[Actions.FETCH_CAMPAIGN_CATEGORIES](), getQuietHours()];
        Promise.all(promises)
            .then(response => {
                this.qhResponse = response[1].data;
                this.$Progress.finish();
            })
            .catch((e: any) => {
                Sentry.captureException(e);
                this.$Progress.fail();
                this.$alert('settings.campaignCategories.failedToLoadAll');
            })
            .finally(() => {
                this.isDataLoading = false;
            });
    },
    methods: {
        isUserAllowed,
        isEmpty,
        ...mapActions(Modules.campaigns, [Actions.FETCH_CAMPAIGN_CATEGORIES]),
        onSelectEntity(id: string | number) {
            this.selectedCategoryId = id;
            this.selectedCategory = this.allCategories.find(e => e.id === id);
            this.isOverviewEnabled = true;
        },
        onAddNewClick() {
            this.$router.push({
                name: RouteNames.CAMPAIGN_CATEGORIES_EDITOR,
            });
        },
    },
};
