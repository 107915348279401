<template>
    <AbstractListPageWrapper
        :pageTitle="pageTitle"
        :entitiesCount="entitiesCount"
        :isOverviewEnabled="isOverviewEnabled"
        @searchQueryChanged="onSearchQueryChanged"
    >
        <template slot="button">
            <ResponseModalButton
                :response="entityResponse"
                :title="pageTitle"
            />
        </template>

        <template slot="filterTable">
            <FilterTable
                :columns="tableColumnsData"
                @filterAdded="onFilterAdded"
            />
        </template>

        <template slot="headerButtons">
            <AppButton
                v-if="writePermission"
                :buttonType="BUTTON_TYPES.PRIMARY"
                :iconType="ICON_TYPES.PLUS"
                :label="addButtonLabel"
                data-test-id="add-entity-button"
                @click="onAdd"
            />
        </template>

        <template slot="allFilters">
            <TableFiltersTags
                :filterTableMixin="filterTableMixin"
                class="my-3 ml-2"
                @removeFilter="removeFilter"
                @removeAllFilters="removeAllFilters"
            />
        </template>

        <template slot="customHtml">
            <div class="m-4">
                <AppMultiselectV3
                    v-model="selectedPartyRole"
                    :additionalLabel="$i18n.t('operateAPIs.selectChannelPartner')"
                    :options="partyRoleOptions"
                    :small="true"
                    :allowEmpty="false"
                    label="name"
                    optionId="id"
                    class="party-role-multiselect"
                />
            </div>
        </template>

        <template slot="table">
            <AppTable
                :entities="formattedFilteredEntities"
                :innerSearchQuery="searchQueryForTable"
                :isSearchEnabled="true"
                :columnsData="tableColumnsData"
                :tableKey="`${entityType}-table`"
                :data-test-id="`${entityType}-table`"
                @selectEntity="selectEntity"
            >
                <template #customRowButtons="{ entity }">
                    <IconButton
                        v-if="entity.approvalStatus"
                        :label="$i18n.t('generic.edit')"
                        :icon="ICON_TYPES.EDIT"
                        class="mr-2"
                        @iconClick="onOpenEditEntityModal(entity)"
                    />
                </template>
            </AppTable>
        </template>

        <template slot="overview">
            <EntityOverview
                :entityType="entityType"
                :entity="selectedEntity"
                @closeOverview="isOverviewEnabled = false"
            >
                <div
                    slot="section-1-content"
                    key="1"
                >
                    <slot name="sidebarOverview" />

                    <h2 class="lf-title mb-2 mt-3">
                        {{ $i18n.t('formBuilderTypes.JSON') }}
                    </h2>
                    <VueJsonPretty
                        selectableType="single"
                        :data="selectedEntityJSON"
                        :deep="2"
                        class="state-history-content"
                    />
                </div>
            </EntityOverview>
        </template>

        <template slot="modal">
            <AppDialogV2
                :title="$i18n.t('operateAPIs.editEntity')"
                :visible="isEditEntityModalVisible"
                @close="isEditEntityModalVisible = false"
            >
                <AppMultiselectV3
                    v-model="selectedApprovalStatus"
                    :additionalLabel="$i18n.t('operateAPIs.approvalStatus')"
                    :options="approvalStatusOptions"
                    :small="true"
                    :showLabels="false"
                    label="label"
                    optionId="id"
                />
                <template #modalFooter>
                    <AppButton
                        :buttonType="BUTTON_TYPES.PRIMARY"
                        :label="$t('generic.save')"
                        class="mr-3"
                        @click="onEditEntity"
                    />
                </template>
            </AppDialogV2>

            <slot name="additionalModal" />
        </template>
    </AbstractListPageWrapper>
</template>

<script>
// Components
import AppDialogV2 from '@/components/partials/AppDialogV2.vue';
import AppMultiselectV3 from '@/components/partials/inputs/AppMultiselectV3.vue';
import AbstractListPageWrapper from '@/components/layout/AbstractListPageWrapper.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import AppTable from '@/components/partials/AppTable.vue';
import FilterTableMixin from '@/components/partials/FilterTableMixin.vue';
import ListPageMixin from '@/__new__/features/pc/ListPageMixin.vue';
import FilterTable from '@/components/partials/FilterTable.vue';
import TableFiltersTags from '@/components/filters/TableFiltersTags.vue';
import ResponseModalButton from '@/components/partials/ResponseModalButton.vue';
import EntityOverview from '@/components/partials/entityOverview/EntityOverview.vue';
import IconButton from '@/components/partials/IconButton.vue';

// 3rd party components
import VueJsonPretty from 'vue-json-pretty';

// Helpers
import { ICON_TYPES } from '@/common/iconHelper';
import tableColumnType from '@/common/filterTable';

// Http
import { getPartyRoles } from '@/__new__/services/dno/partyRolesPermissionsManagement/http/partyRolesPermissionsManagement';

const APPROVAL_STATUS_OPTIONS = {
    APPROVED: 'approved',
    REJECTED: 'rejected',
    PEDNING_APPROVAL: 'pendingApproval',
};

export default {
    name: 'OperateApisListPageLayout',

    components: {
        AppMultiselectV3,
        AppDialogV2,
        AppTable,
        AppButton,
        AbstractListPageWrapper,
        ResponseModalButton,
        TableFiltersTags,
        FilterTable,
        EntityOverview,
        VueJsonPretty,
        IconButton,
    },

    mixins: [FilterTableMixin, ListPageMixin],

    props: {
        writePermission: {
            type: Boolean,
            required: false,
            default: false,
        },
        entityType: {
            type: String,
            required: true,
        },
        pageTitle: {
            type: String,
            default: '',
        },
        entityResponse: {
            type: Object,
            default: () => undefined,
        },
        entityData: {
            type: Array,
            default: () => undefined,
        },
        addButtonLabel: {
            type: String,
            default: '',
        },
        addNewRouteName: {
            type: String,
            default: '',
        },
    },

    data() {
        return {
            approvalStatusOptions: [
                { label: this.$i18n.t('generic.stateMap.approved'), id: APPROVAL_STATUS_OPTIONS.APPROVED },
                { label: this.$i18n.t('generic.stateMap.rejected'), id: APPROVAL_STATUS_OPTIONS.REJECTED },
                {
                    label: this.$i18n.t('generic.stateMap.pendingApproval'),
                    id: APPROVAL_STATUS_OPTIONS.PEDNING_APPROVAL,
                },
            ],
            searchQueryForTable: '',
            isOverviewEnabled: false,
            selectedEntity: null,
            selectedEntityJSON: {},
            selectedApprovalStatus: '',
            selectedEnityId: '',
            isEditEntityModalVisible: false,
            partyRoleOptions: [],
            selectedPartyRole: null,

            ICON_TYPES,
            BUTTON_TYPES,
        };
    },

    computed: {
        formattedFilteredEntities() {
            return this.filteredEntitiesMixin(this.entityData);
        },
        entitiesCount() {
            return this.entityData?.length || 0;
        },
        tableColumnsData() {
            const columns = [
                {
                    name: this.$i18n.t('generic.description'),
                    key: 'description',
                    field: 'description',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
            ];

            if (this.entityType === 'api_product_order') {
                columns.unshift({
                    name: this.$i18n.t('generic.id'),
                    key: 'id',
                    field: 'id',
                    filterType: tableColumnType.GENERAL_TEXT,
                });
            } else {
                columns.unshift({
                    name: this.$i18n.t('generic.name'),
                    key: 'name',
                    field: 'name',
                    filterType: tableColumnType.GENERAL_TEXT,
                });
            }

            return columns;
        },
    },

    watch: {
        selectedPartyRole: {
            handler(val) {
                this.$emit('onSelectPartyRole', val);
            },
        },
    },

    created() {
        this.$withProgressBar(
            async () => {
                const response = await getPartyRoles('ChannelPartner');

                this.partyRoleOptions = response?.data?.map(partyRole => ({
                    id: partyRole?.id || '',
                    name: partyRole?.name || '',
                }));

                [{ id: this.selectedPartyRole }] = this.partyRoleOptions;
            },
            {
                errorHandler: () => {
                    this.$alert(this.$i18n.t('alertMessage.somethingWentWrongFetchingNecessaryData'));
                },
            },
        );
    },

    methods: {
        onSearchQueryChanged(searchQuery) {
            this.searchQueryForTable = searchQuery;
        },
        onAdd() {
            this.$router.push({ name: this.addNewRouteName });
        },
        selectEntity(entity) {
            this.selectedEntity = this.entityData.find(pr => pr.id === entity);
            this.isOverviewEnabled = true;
            this.$emit('onSelectEntity', entity);

            // Set value for JSON editor
            this.selectedEntityJSON = this.entityResponse.data.find(obj => obj.id === entity);
        },
        onOpenEditEntityModal(entity) {
            this.selectedEnityId = entity.id;
            this.selectedApprovalStatus = entity.approvalStatus;
            this.isEditEntityModalVisible = true;
        },
        onEditEntity() {
            const updatedEntity = { id: this.selectedEnityId, selectedApprovalOption: this.selectedApprovalStatus };
            this.$emit('onEditEntity', updatedEntity);
            this.isEditEntityModalVisible = false;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/consistency';

.state-history-content {
    border: 1px solid $gray-200;
    padding: $spacing-m !important;
}

.party-role-multiselect {
    width: 50% !important;
}
</style>
