// Store
import store, { Modules } from '@/store/store';
import { Getters } from '@/store/mutation-types';

// Models
import {
    SinkConfig,
    ORD_SINK_MAPPING,
    replaceIdByEventTypeInTriggerCondition,
    type SinkSpecificConfig,
} from '@/__new__/services/dno/sinkConfigs/models/SinkConfig';

// Helpers
import { capitalize, isEmpty } from 'lodash';
import { SINK_CONFIG_STATUS } from '@/__new__/services/dno/sinkConfigs/models/SinkConfigStatus';
import { sinkConfigColFromJson } from '@/__new__/services/dno/sinkConfigs/models/SinkConfigCol';
import { type ORD_CONFIG_STATUS } from '@/common/sinkConfigHelper';
import { type JOB_TYPE } from '@/__new__/services/dno/sinkConfigs/models/sinkConfigHelper';
import { type JobDefinitionItem } from '@/store/modules/sinkConfigs.store';
import { configCohortsDataFromJson } from '@/__new__/services/dno/sinkConfigs/models/CohortConfig';

export type OrdReportJobDefinition = {
    id: string;
    name: string;
    description: string;
    cluster_name: string;
    cron_expression: string;
    delimiter: string;
    extension: string;
    file_name: string;
    frequency: string;
    header: string;
    interval_value: string;
    job_type: JOB_TYPE;
    secondary_file_destination: string;
    sink_entity_properties: Record<string, any>;
    timezone: string;
    visible_on_portal: string;
    empty_files: boolean;
};

interface ORDSinkSpecificConfig extends SinkSpecificConfig {
    id: string;
    cluster_name: string;
    description: string;
    delimiter: string;
    extension: string;
    file_name: string;
    header: string;
    secondary_file_destination: string;
    type: SinkSpecificConfig['type'] & 'CronExpressionInput';
    timezone: string;
    visible_on_portal: string;
    empty_files: boolean;
}

export default class ORDSinkConfig extends SinkConfig {
    // Type definition difference from SinkConfig.
    declare specificConfig: ORDSinkSpecificConfig;
    declare state: ORD_CONFIG_STATUS;

    static mapFromReportsResponse({
        job_definition: {
            name,
            id,
            sink_entity_properties: sinkEntityProperties,
            description,
            frequency,
            cron_expression,
            interval_value,
            file_name,
            secondary_file_destination,
            delimiter,
            extension,
            cluster_name,
            timezone,
            visible_on_portal,
            empty_files,
            header,
            job_type: jobType,
        },
        last_updated,
        revision_number,
        state,
    }: JobDefinitionItem<OrdReportJobDefinition>) {
        const events = store.getters[`${Modules.events}/${Getters.GET_MAPPED_EVENTS}`];
        const formatters = store.getters[`${Modules.sinkConfigs}/${Getters.GET_SINK_FORMATTERS}`];
        const uiSettings = store.getters[`${Modules.sinkConfigs}/${Getters.GET_ORD_CONFIGS_SETTINGS}`];
        const filters = replaceIdByEventTypeInTriggerCondition(sinkEntityProperties?.trigger_condition)?.filters;
        const segments = store.getters[`${Modules.segments}/${Getters.GROUPED_SEGMENTS_BY_ID_TYPE}`]
            .map((group: any) => group.groupValues)
            .flat();

        return new ORDSinkConfig({
            name,
            id,
            filters,
            specificConfig: {
                id,
                name,
                description,
                // Conditional logic needed until BE updates the values.
                //  - new: 'cron_expression'
                //  - legacy: 'frequency', 'interval_value'
                ...(cron_expression ? { cron_expression } : { frequency, interval_value }),
                file_name,
                secondary_file_destination,
                delimiter,
                extension,
                cluster_name,
                timezone,
                visible_on_portal,
                empty_files,
                header: header ? ORD_SINK_MAPPING.HEADER_VALUES.YES : ORD_SINK_MAPPING.HEADER_VALUES.NO, // TODO this is a discrepancy between UI and ORD
            } as unknown as ORDSinkSpecificConfig,
            version: revision_number,
            state: state ? capitalize(state) : SINK_CONFIG_STATUS.DRAFT,
            fieldsConfiguration: sinkConfigColFromJson(
                sinkEntityProperties?.fields_configuration ?? [],
                state ? capitalize(state) : SINK_CONFIG_STATUS.DRAFT,
                events,
                formatters,
                uiSettings,
            ),
            updateTime: last_updated,
            jobType,
            allowFieldsConfigurationEdit: !isEmpty(sinkEntityProperties),
            cohortsData: isEmpty(sinkEntityProperties?.cohorts_configuration)
                ? undefined
                : configCohortsDataFromJson(sinkEntityProperties?.cohorts_configuration, filters, segments),
        });
    }
}
