<template>
    <OperateApisListPageLayout
        :pageTitle="$i18n.tc('operateAPIs.applicationOwners', PLURALIZATION.PLURAL)"
        :entityType="'application_owner'"
        :entityResponse="applicationOwnersApiResponse"
        :entityData="entityData"
        :addButtonLabel="$i18n.t('operateAPIs.addNewApplicationOwner')"
        :writePermission="isUserAllowed('OperateAPIsApplicationOwnersWrite')"
        :addNewRouteName="addNewRouteName"
        @onSelectEntity="onSelectEntity"
        @onEditEntity="onEditEntity"
        @onSelectPartyRole="onSelectPartyRole"
    >
        <template slot="sidebarOverview">
            <OverviewList :lists="detailsSections" />
        </template>
    </OperateApisListPageLayout>
</template>

<script>
// Components
import OperateApisListPageLayout from '@/__new__/features/operateAPIs/OperateApisListPageLayout.vue';
import OverviewList from '@/components/partials/entityOverview/OverviewList.vue';

// Http
import { getApplicationOwners, updateApplicationOwner } from '@/__new__/services/dno/operateAPIs/http/operateAPIs';

// Helpers
import { isUserAllowed } from '@/services/permissions/permissions.service';
import RouteNames from '@/router/routeNames';
import { PLURALIZATION } from '@/common/locale/labelSingularOrPlural';

export default {
    name: 'ApplicationOwnersListPage',

    components: {
        OperateApisListPageLayout,
        OverviewList,
    },

    data() {
        return {
            selectedChannelPartnerId: '',
            applicationOwnersApiResponse: {},
            entityData: [],
            selectedApiOwner: '',
            addNewRouteName: RouteNames.OPERATE_APIS_APPLICATION_OWNER_EDITOR,
            PLURALIZATION,
        };
    },

    computed: {
        selectedEntityId() {
            return this.selectedApiOwner ? this.selectedApiOwner.id : null;
        },
        detailsSections() {
            if (!this.selectedEntityId) {
                return [];
            }

            return [
                {
                    name: this.$i18n.t('generic.general'),
                    properties: [
                        {
                            value: this.selectedApiOwner?.id || this.$i18n.t('generic.empty'),
                            label: this.$i18n.t('generic.id'),
                        },
                        {
                            value: this.selectedApiOwner?.status || this.$i18n.t('generic.empty'),
                            label: this.$i18n.t('generic.status'),
                        },
                        {
                            value: this.selectedApiOwner?.approvalStatus || this.$i18n.t('generic.empty'),
                            label: this.$i18n.t('operateAPIs.approvalStatus'),
                        },
                        {
                            value: this.selectedApiOwner?.streetName || this.$i18n.t('generic.empty'),
                            label: this.$i18n.t('operateAPIs.streetName'),
                        },
                        {
                            value: this.selectedApiOwner?.streetNr || this.$i18n.t('generic.empty'),
                            label: this.$i18n.t('operateAPIs.streetNr'),
                        },
                        {
                            value: this.selectedApiOwner?.locality || this.$i18n.t('generic.empty'),
                            label: this.$i18n.t('operateAPIs.locality'),
                        },
                        {
                            value: this.selectedApiOwner?.city || this.$i18n.t('generic.empty'),
                            label: this.$i18n.t('generic.city'),
                        },
                    ],
                },
            ];
        },
    },

    methods: {
        isUserAllowed,
        onSelectEntity(entity) {
            [this.selectedApiOwner] = this.entityData.filter(data => data.id === entity);
        },
        async onEditEntity(entity) {
            await this.$withProgressBar(
                async () => {
                    await updateApplicationOwner({
                        id: entity.id,
                        payload: { approvalStatus: entity.selectedApprovalOption },
                        channelPartnerId: this.selectedChannelPartnerId,
                    });

                    this.$showSuccessAlert({
                        message: this.$i18n.t('operateAPIs.successfullyUpdatedApplicationOwner'),
                    });

                    this.$router.go();
                },
                {
                    errorHandler: () => {
                        this.$alert(this.$i18n.t('operateAPIs.somethingWentWrongUpdatedApplicationOwner'));
                    },
                },
            );
        },
        onSelectPartyRole(id) {
            this.$withLoadingSpinner(
                async () => {
                    await this.fetchApplicationOwners(id);
                    this.selectedChannelPartnerId = id;
                },
                {
                    errorHandler: () => {
                        this.$alert(this.$t('operateAPIs.somethingWentWrongFetchingApplicationOwners'));
                    },
                },
            );
        },
        async fetchApplicationOwners(partyRoleId) {
            const response = await getApplicationOwners(partyRoleId);
            this.applicationOwnersApiResponse = response;

            this.entityData = response.data.map(appOwner => {
                const geographicAddress = appOwner?.engagedParty?.registeredGeographicAddress || {};

                return {
                    id: appOwner.id || '',
                    description: appOwner?.description || '',
                    name: appOwner?.name || '',
                    status: appOwner?.status || '',
                    approvalStatus: appOwner?.approvalStatus || '',
                    streetName: geographicAddress?.streetName || '',
                    streetNr: geographicAddress?.streetNr || '',
                    locality: geographicAddress?.locality || '',
                    city: geographicAddress?.city || '',
                };
            });
        },
    },
};
</script>
