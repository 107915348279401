<template>
    <OperateApisListPageLayout
        :pageTitle="$i18n.t('operateAPIs.apiProductOrders')"
        :entityType="'api_product_order'"
        :entityResponse="apiProductOrdersApiResponse"
        :entityData="entityData"
        :addButtonLabel="$i18n.t('operateAPIs.addNewApiProductOrder')"
        :writePermission="isUserAllowed('OperateAPIsProductOrdersWrite')"
        :addNewRouteName="addNewRouteName"
        @onSelectEntity="onSelectApiProductOrder"
        @onSelectPartyRole="onSelectPartyRole"
    >
        <template slot="sidebarOverview">
            <OverviewList :lists="detailsSections" />

            <template v-if="isUserInternal">
                <h2 class="lf-table-title mb-2 mt-4">
                    {{ $i18n.t('operateAPIs.relatedExecutions') }}
                </h2>
                <AppTable
                    :entities="executionsForEntity"
                    :columnsData="tableColumnsData"
                    :isDataLoading="executionsTableLoading"
                    :canSelectColumns="true"
                >
                    <template #executionId="{ entity }">
                        <div
                            v-if="entity.executionId"
                            class="truncate-text"
                        >
                            <router-link
                                :to="{
                                    name: RouteNames.ORCHESTRATION_ENGINE_EXECUTION_DETAILS,
                                    params: {
                                        id: 'create_application',
                                        execId: entity.executionId,
                                    },
                                }"
                                target="_blank"
                            >
                                {{ entity.executionId }}
                            </router-link>
                        </div>
                    </template>
                    <template #status="{ entity }">
                        <ExecutionStatusIndicator
                            v-if="entity.status"
                            :status="entity.status"
                        />
                    </template>
                    <template #customRowButtons="{ entity }">
                        <IconButton
                            v-if="
                                (entity.lastState === 'rover_create_application' || entity.lastState === 'add_scope') &&
                                entity.status === EXECUTION_STATES.WAITINGFORCALLBACK
                            "
                            :label="$i18n.t('operateAPIs.approveReject')"
                            :icon="ICON_TYPES.REGENERATE"
                            class="mr-2"
                            @iconClick="openSubmitCallbackApiProductOrderModal(entity)"
                        />
                    </template>
                </AppTable>
            </template>
            <template v-if="!isUserInternal && Object.keys(lastMappedExecution).length">
                <AppButton
                    :buttonType="BUTTON_TYPES.PRIMARY"
                    :label="$i18n.t('operateAPIs.approveReject')"
                    @click="openSubmitCallbackApiProductOrderModal(lastMappedExecution)"
                />
            </template>
        </template>

        <template slot="additionalModal">
            <AppDialogV2
                :title="$i18n.t('operateAPIs.approveReject')"
                :visible="submitCallbackApiProductOrderModalVisible"
                @close="submitCallbackApiProductOrderModalVisible = false"
            >
                <AppCheckbox
                    v-model="submitApiProductOrderCallbackData.isApproved"
                    :labelRight="$i18n.t('generic.stateMap.approved')"
                    class="mt-2"
                />
                <template #modalFooter>
                    <AppButton
                        :buttonType="BUTTON_TYPES.PRIMARY"
                        :label="$i18n.t('generic.submit')"
                        class="mr-3"
                        @click="approveRejectApiProductOrder"
                    />
                </template>
            </AppDialogV2>
        </template>
    </OperateApisListPageLayout>
</template>

<script>
// Components
import OperateApisListPageLayout from '@/__new__/features/operateAPIs/OperateApisListPageLayout.vue';
import OverviewList from '@/components/partials/entityOverview/OverviewList.vue';
import AppTable from '@/components/partials/AppTable.vue';
import ExecutionStatusIndicator from '@/__new__/features/orchestrationengine/ExecutionStatusIndicator.vue';
import IconButton from '@/components/partials/IconButton.vue';
import AppDialogV2 from '@/components/partials/AppDialogV2.vue';
import AppCheckbox from '@/components/partials/inputs/AppCheckbox.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';

// HTTP
import { getApiProductOrders } from '@/__new__/services/dno/operateAPIs/http/operateAPIs';
import { callbackExecution } from '@/__new__/services/dno/orchestrationengine/http/orchestration-engine';

// Helpers
import RouteNames from '@/router/routeNames';
import tableColumnType from '@/common/filterTable';
import { EXECUTION_STATES } from '@/__new__/features/orchestrationengine/common/executionStatusIndicatorHelper';
import { ICON_TYPES } from '@/common/iconHelper';
import { isUserInternal, isUserAllowed } from '@/services/permissions/permissions.service';
import Actions, { Getters } from '@/store/mutation-types';

// Vuex
import { mapActions, mapGetters } from 'vuex';

export default {
    name: 'ApiProductOrdersListPage',

    components: {
        AppDialogV2,
        AppCheckbox,
        AppButton,
        OperateApisListPageLayout,
        OverviewList,
        AppTable,
        ExecutionStatusIndicator,
        IconButton,
    },

    data() {
        return {
            apiProductOrdersApiResponse: {},
            entityData: [],
            addNewRouteName: RouteNames.OPERATE_APIS_PRODUCT_ORDERS_EDITOR,
            selectedApiProductOrder: null,
            executionsForEntity: [],
            executionsTableLoading: false,
            lastMappedExecution: {},
            submitCallbackApiProductOrderModalVisible: false,
            submitApiProductOrderCallbackData: {
                isApproved: false,
                executionId: '',
                planId: '',
            },

            tableColumnsData: [
                {
                    name: this.$i18n.t('orchestrationEngine.execId'),
                    key: 'executionId',
                    field: 'executionId',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('generic.status'),
                    key: 'status',
                    field: 'status',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
            ],

            BUTTON_TYPES,
            RouteNames,
            EXECUTION_STATES,
            ICON_TYPES,
            isUserInternal,
        };
    },

    computed: {
        ...mapGetters('orchestrationengine', {
            getOEMappedExecutions: Getters.GET_OE_MAPPED_EXECUTIONS,
        }),
        selectedEntityId() {
            return this.selectedApiProductOrder ? this.selectedApiProductOrder.id : null;
        },
        detailsSections() {
            if (!this.selectedEntityId) {
                return [];
            }

            return [
                {
                    name: this.$i18n.t('generic.general'),
                    properties: [
                        {
                            value: this.selectedApiProductOrder?.state || this.$i18n.t('generic.empty'),
                            label: this.$i18n.t('generic.state'),
                        },
                        {
                            value: this.selectedApiProductOrder?.createOn || this.$i18n.t('generic.empty'),
                            label: this.$i18n.t('benefits.creationDate'),
                        },
                        {
                            value: this.selectedApiProductOrder?.completionDate || this.$i18n.t('generic.empty'),
                            label: this.$i18n.t('customerCare.userInformation.portingColumns.dateCompletion'),
                        },
                        {
                            value: this.selectedApiProductOrder?.targetApplication || this.$i18n.t('generic.empty'),
                            label: this.$i18n.t('operateAPIs.targetApplication'),
                        },
                    ],
                },
            ];
        },
    },

    methods: {
        ...mapActions('orchestrationengine', {
            requestOEMappedExecutions: Actions.REQUEST_OE_MAPPED_EXECUTIONS,
        }),
        isUserAllowed,
        onSelectPartyRole(id) {
            this.$withLoadingSpinner(
                async () => {
                    await this.fetchApiProductOrders(id);
                },
                {
                    errorHandler: () => {
                        this.$alert(this.$t('operateAPIs.somethingWentWrongFetchingApiProductOrders'));
                    },
                },
            );
        },
        async fetchApiProductOrders(partyRoleId) {
            const response = await getApiProductOrders(partyRoleId);
            this.apiProductOrdersApiResponse = response;

            this.entityData = response.data.map(apiProductOrder => ({
                id: apiProductOrder?.id || '',
                description: apiProductOrder?.description || '',
                state: apiProductOrder?.state || '',
                createOn: apiProductOrder?.createOn || '',
                completionDate: apiProductOrder?.completionDate || '',
                targetApplication: apiProductOrder?.productOrderItem?.productAction?.targetApplication || '',
            }));
        },
        onSelectApiProductOrder(applicationId) {
            this.$withLoadingSpinner(
                async () => {
                    [this.selectedApiProductOrder] = this.entityData.filter(data => data.id === applicationId);

                    // Reset displayed executions
                    this.executionsForEntity = [];

                    // Set flag for table loading
                    this.executionsTableLoading = true;

                    await this.requestOEMappedExecutions({
                        plan_ids: ['create_api_product_order'],
                        mapper_ids: [applicationId],
                    });

                    // Set executions for selected entity
                    this.executionsForEntity = this.getOEMappedExecutions;

                    // Get the latest execution in order to have that data for approve/reject button for non lf admins
                    const filteredRelatedExecutions = this.executionsForEntity.filter(
                        execution =>
                            execution.status === EXECUTION_STATES.WAITINGFORCALLBACK &&
                            (execution.lastState === 'rover_create_application' || execution.lastState === 'add_scope'),
                    );

                    if (filteredRelatedExecutions.length) {
                        this.lastMappedExecution = this.executionsForEntity[this.executionsForEntity.length - 1];
                    }

                    // Reset flag for table loading
                    this.executionsTableLoading = false;
                },
                {
                    errorHandler: () => {
                        this.$alert(this.$t('operateAPIs.somethingWentWrongLoadingApplicationExecutions'));
                    },
                },
            );
        },
        openSubmitCallbackApiProductOrderModal(entity) {
            this.submitApiProductOrderCallbackData = {
                ...this.submitApiProductOrderCallbackData,
                executionId: entity.executionId,
                planId: entity.planId,
            };

            this.submitCallbackApiProductOrderModalVisible = true;
        },
        approveRejectApiProductOrder() {
            this.$withLoadingSpinner(
                async () => {
                    const { executionId, planId, isApproved } = this.submitApiProductOrderCallbackData;

                    await callbackExecution({
                        execution_id: executionId,
                        plan_id: planId,
                        approved: isApproved,
                    });

                    this.$router.go();
                },
                {
                    errorHandler: () => {
                        this.$alert(this.$t('operateAPIs.somethingWentWrongApproveRejecApiProductOrder'));
                    },
                },
            );
        },
    },
};
</script>
