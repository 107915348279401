<template>
    <OperateApisListPageLayout
        :pageTitle="$i18n.t('operateAPIs.applications')"
        :entityType="'application'"
        :entityResponse="applicationsApiResponse"
        :entityData="entityData"
        :addButtonLabel="$i18n.t('operateAPIs.addNewApplication')"
        :writePermission="isUserAllowed('OperateAPIsApplicationsWrite')"
        :addNewRouteName="addNewRouteName"
        @onSelectEntity="onSelectApplication"
        @onEditEntity="onEditEntity"
        @onSelectPartyRole="onSelectPartyRole"
    >
        <template slot="sidebarOverview">
            <h2 class="lf-table-title mb-2">
                {{ $i18n.t('operateAPIs.transactions') }}
            </h2>
            <template v-if="transactionsToDisplay.length">
                <div
                    v-for="(transaction, index) in transactionsToDisplay"
                    :key="`transaction-${index}`"
                    class="mb-4"
                >
                    <div class="d-flex">
                        <p class="heading-xs mr-3">{{ `${$i18n.t('customerCare.transactionHistory.offerName')}:` }}</p>
                        <p>{{ transaction.offerName }}</p>
                    </div>
                    <div class="d-flex">
                        <p class="heading-xs mr-3">{{ `${$i18n.t('operateAPIs.chargingSpecName')}:` }}</p>
                        <p>{{ transaction.chargingSpecName }}</p>
                    </div>
                    <div class="d-flex">
                        <p class="heading-xs mr-3">{{ `${$i18n.t('operateAPIs.amountUsed')}:` }}</p>
                        <p>{{ transaction.amountUsed }}</p>
                    </div>
                </div>
            </template>
            <p
                v-else
                class="body-sm"
            >
                {{ $i18n.t('generic.empty') }}
            </p>

            <template v-if="isUserInternal">
                <h2 class="lf-table-title mb-2 mt-4">
                    {{ $i18n.t('operateAPIs.relatedExecutions') }}
                </h2>
                <AppTable
                    :entities="executionsForEntity"
                    :columnsData="tableColumnConfig"
                    :isDataLoading="executionsTableLoading"
                    :canSelectColumns="true"
                >
                    <template #executionId="{ entity }">
                        <div
                            v-if="entity.executionId"
                            class="truncate-text"
                        >
                            <router-link
                                :to="{
                                    name: RouteNames.ORCHESTRATION_ENGINE_EXECUTION_DETAILS,
                                    params: {
                                        id: 'create_application',
                                        execId: entity.executionId,
                                    },
                                }"
                                target="_blank"
                            >
                                {{ entity.executionId }}
                            </router-link>
                        </div>
                    </template>
                    <template #status="{ entity }">
                        <ExecutionStatusIndicator
                            v-if="entity.status"
                            :status="entity.status"
                        />
                    </template>
                    <template #customRowButtons="{ entity }">
                        <IconButton
                            v-if="
                                entity.lastState === 'register_new_application' &&
                                entity.status === EXECUTION_STATES.WAITINGFORCALLBACK
                            "
                            :label="$i18n.t('operateAPIs.approveReject')"
                            :icon="ICON_TYPES.REGENERATE"
                            class="mr-2"
                            @iconClick="openSubmitCallbackApplicationModal(entity)"
                        />
                    </template>
                </AppTable>
            </template>
            <template v-if="!isUserInternal && Object.keys(lastMappedExecution).length">
                <AppButton
                    :buttonType="BUTTON_TYPES.PRIMARY"
                    :label="$i18n.t('operateAPIs.approveReject')"
                    @click="openSubmitCallbackApplicationModal(lastMappedExecution)"
                />
            </template>

            <OverviewList :lists="detailsSections" />
        </template>

        <template slot="additionalModal">
            <AppDialogV2
                :title="$i18n.t('operateAPIs.approveReject')"
                :visible="submitCallbackApplicationModalVisible"
                @close="submitCallbackApplicationModalVisible = false"
            >
                <AppInputV3
                    v-model="submitApplicationCallbackData.clientId"
                    :label="$i18n.t('orderResume.clientId')"
                />
                <AppInputV3
                    v-model="submitApplicationCallbackData.clientSecret"
                    :label="$i18n.t('orderResume.clientSecret')"
                    class="mt-2"
                />
                <AppCheckbox
                    v-model="submitApplicationCallbackData.isApproved"
                    :labelRight="$i18n.t('generic.stateMap.approved')"
                    class="mt-2"
                />
                <template #modalFooter>
                    <AppButton
                        :buttonType="BUTTON_TYPES.PRIMARY"
                        :label="$i18n.t('generic.submit')"
                        class="mr-3"
                        @click="approveRejectApplication"
                    />
                </template>
            </AppDialogV2>
        </template>
    </OperateApisListPageLayout>
</template>

<script>
// Components
import OperateApisListPageLayout from '@/__new__/features/operateAPIs/OperateApisListPageLayout.vue';
import OverviewList from '@/components/partials/entityOverview/OverviewList.vue';
import AppTable from '@/components/partials/AppTable.vue';
import ExecutionStatusIndicator from '@/__new__/features/orchestrationengine/ExecutionStatusIndicator.vue';
import IconButton from '@/components/partials/IconButton.vue';
import AppDialogV2 from '@/components/partials/AppDialogV2.vue';
import AppButton, { BUTTON_TYPES } from '@/components/partials/inputs/AppButton.vue';
import AppInputV3 from '@/components/partials/inputs/AppInputV3.vue';
import AppCheckbox from '@/components/partials/inputs/AppCheckbox.vue';

// HTTP
import {
    getApplications,
    getApiTransactions,
    updateApplication,
} from '@/__new__/services/dno/operateAPIs/http/operateAPIs';
import { callbackExecution } from '@/__new__/services/dno/orchestrationengine/http/orchestration-engine';

// Helpers
import { isUserAllowed, isUserInternal } from '@/services/permissions/permissions.service';
import RouteNames from '@/router/routeNames';
import tableColumnType from '@/common/filterTable';
import Actions, { Getters } from '@/store/mutation-types';
import { ICON_TYPES } from '@/common/iconHelper';
import { EXECUTION_STATES } from '@/__new__/features/orchestrationengine/common/executionStatusIndicatorHelper';

// Vuex
import { mapActions, mapGetters } from 'vuex';

export default {
    name: 'ApplicationsListPage',

    components: {
        AppDialogV2,
        AppButton,
        AppTable,
        AppInputV3,
        AppCheckbox,
        ExecutionStatusIndicator,
        OperateApisListPageLayout,
        OverviewList,
        IconButton,
    },

    data() {
        return {
            applicationsApiResponse: {},
            entityData: [],
            transactions: [],
            transactionsToDisplay: [],
            addNewRouteName: RouteNames.OPERATE_APIS_APPLICATIONS_EDITOR,
            selectedApplication: null,
            executionsForEntity: [],
            executionsTableLoading: false,
            submitCallbackApplicationModalVisible: false,
            lastMappedExecution: {},
            selectedChannelPartnerId: '',
            submitApplicationCallbackData: {
                clientId: '',
                clientSecret: '',
                isApproved: false,
                executionId: '',
                planId: '',
            },

            tableColumnConfig: [
                {
                    name: this.$i18n.t('orchestrationEngine.execId'),
                    key: 'executionId',
                    field: 'executionId',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
                {
                    name: this.$i18n.t('generic.status'),
                    key: 'status',
                    field: 'status',
                    filterType: tableColumnType.GENERAL_TEXT,
                },
            ],

            isUserInternal,
            RouteNames,
            ICON_TYPES,
            EXECUTION_STATES,
            BUTTON_TYPES,
        };
    },

    computed: {
        ...mapGetters('orchestrationengine', {
            getOEMappedExecutions: Getters.GET_OE_MAPPED_EXECUTIONS,
        }),
        selectedEntityId() {
            return this.selectedApplication ? this.selectedApplication.id : null;
        },
        detailsSections() {
            if (!this.selectedEntityId) {
                return [];
            }

            return [
                {
                    name: this.$i18n.t('generic.general'),
                    properties: [
                        {
                            value: this.selectedApplication?.id || this.$i18n.t('generic.empty'),
                            label: this.$i18n.t('generic.id'),
                        },
                        {
                            value: this.selectedApplication?.commercialName || this.$i18n.t('generic.empty'),
                            label: this.$i18n.t('operateAPIs.commercialName'),
                        },
                        {
                            value: this.selectedApplication?.category || this.$i18n.t('generic.empty'),
                            label: this.$i18n.t('productCatalog.entities.category'),
                        },
                        {
                            value: this.selectedApplication?.operationalState || this.$i18n.t('generic.empty'),
                            label: this.$i18n.t('operateAPIs.operationalState'),
                        },
                        {
                            value: this.selectedApplication?.approvalStatus || this.$i18n.t('generic.empty'),
                            label: this.$i18n.t('operateAPIs.operateAPIs'),
                        },
                        {
                            value: this.selectedApplication?.clientId || this.$i18n.t('generic.empty'),
                            label: this.$i18n.t('orderResume.clientId'),
                        },
                        {
                            value: this.selectedApplication?.redirectURL || this.$i18n.t('generic.empty'),
                            label: this.$i18n.t('operateAPIs.redirectUrl'),
                        },
                    ],
                },
            ];
        },
    },

    async created() {
        await this.$withProgressBar(
            async () => {
                await getApiTransactions({
                    start_timestamp: 0,
                    end_timestamp: Math.round(new Date().getTime() / 1000),
                    target_id: '61bfb733-7c45-4c75-b8a9-960bfa8444af',
                    target_type: 1,
                }).then(resp => {
                    this.transactions = (resp.data.api_transactions || []).map(transaction => ({
                        offerName: transaction?.offer_name || this.$i18n.t('generic.empty'),
                        chargingSpecName: transaction?.charging_spec_name || this.$i18n.t('generic.empty'),
                        amountUsed: transaction?.amount_used || this.$i18n.t('generic.empty'),
                        appId: transaction?.app_id || null,
                    }));
                });
            },
            {
                errorHandler: () => {
                    this.$alert(this.$i18n.t('alertMessage.somethingWentWrongFetchingNecessaryData'));
                },
            },
        );
    },

    methods: {
        ...mapActions('orchestrationengine', {
            requestOEMappedExecutions: Actions.REQUEST_OE_MAPPED_EXECUTIONS,
        }),
        isUserAllowed,
        onSelectApplication(applicationId) {
            this.$withLoadingSpinner(
                async () => {
                    this.transactionsToDisplay = this.transactions.filter(
                        transaction => transaction.appId === applicationId,
                    );
                    [this.selectedApplication] = this.entityData.filter(data => data.id === applicationId);

                    // Reset displayed executions
                    this.executionsForEntity = [];

                    // Set flag for table loading
                    this.executionsTableLoading = true;

                    await this.requestOEMappedExecutions({
                        plan_ids: ['create_application'],
                        mapper_ids: [applicationId],
                    });

                    // Set executions for selected entity
                    this.executionsForEntity = this.getOEMappedExecutions;

                    // Get the latest execution in order to have that data for approve/reject button for non lf admins
                    const filteredRelatedExecutions = this.executionsForEntity.filter(
                        execution =>
                            execution.status === EXECUTION_STATES.WAITINGFORCALLBACK &&
                            execution.lastState === 'register_new_application',
                    );

                    if (filteredRelatedExecutions.length) {
                        this.lastMappedExecution = this.executionsForEntity[this.executionsForEntity.length - 1];
                    }

                    // Reset flag for table loading
                    this.executionsTableLoading = false;
                },
                {
                    errorHandler: () => {
                        this.$alert(this.$t('operateAPIs.somethingWentWrongLoadingApplicationExecutions'));
                    },
                },
            );
        },
        async fetchApplications(partyRoleId) {
            const response = await getApplications(partyRoleId);
            this.applicationsApiResponse = response;

            this.entityData = response.data.map(application => ({
                id: application?.id || '',
                name: application?.name || '',
                description: application?.description || '',
                commercialName: application?.commercialName || '',
                category: application?.category || '',
                operationalState: application?.operationalState || '',
                approvalStatus: application?.approvalStatus || '',
                clientId: application?.digitalIdentity?.clientId || '',
                redirectURL: application?.digitalIdentity?.redirectURL || '',
            }));
        },
        async onEditEntity(entity) {
            await this.$withProgressBar(
                async () => {
                    await updateApplication({
                        id: entity.id,
                        payload: {
                            approvalStatus: entity.selectedApprovalOption,
                        },
                        channelPartnerId: this.selectedChannelPartnerId,
                    });

                    this.$showSuccessAlert({
                        message: this.$i18n.t('operateAPIs.successfullyUpdatedApplication'),
                    });

                    this.$router.go();
                },
                {
                    errorHandler: () => {
                        this.$alert(this.$i18n.t('operateAPIs.somethingWentWrongUpdatedApplication'));
                    },
                },
            );
        },
        onSelectPartyRole(id) {
            this.$withLoadingSpinner(
                async () => {
                    await this.fetchApplications(id);
                    this.selectedChannelPartnerId = id;
                },
                {
                    errorHandler: () => {
                        this.$alert(this.$t('operateAPIs.somethingWentWrongFetchingApplications'));
                    },
                },
            );
        },
        openSubmitCallbackApplicationModal(entity) {
            this.submitApplicationCallbackData = {
                ...this.submitApplicationCallbackData,
                executionId: entity.executionId,
                planId: entity.planId,
            };

            this.submitCallbackApplicationModalVisible = true;
        },
        approveRejectApplication() {
            this.$withLoadingSpinner(
                async () => {
                    const { executionId, planId, isApproved, clientId, clientSecret } =
                        this.submitApplicationCallbackData;

                    await callbackExecution({
                        execution_id: executionId,
                        plan_id: planId,
                        client_id: clientId,
                        client_secret: clientSecret,
                        approved: isApproved,
                    });

                    this.$router.go();
                },
                {
                    errorHandler: () => {
                        this.$alert(this.$t('operateAPIs.somethingWentWrongApproveRejectApplication'));
                    },
                },
            );
        },
    },
};
</script>
